.rootView {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #fff7;
    border-radius: 20px;
    padding: 50px 100px;
    background: #fff2;
    margin: 40px;
}

/* .background {
    position: fixed;
    top: -30px;
    left: -30px;
    min-width: 120%;
    min-height: 120%;
    z-index: 0;
    filter: blur(10px);
} */

.background {
    background-image: linear-gradient(to bottom right, #ff8a48, #ff00c9);
    /* opacity: 0.5; */
    position: fixed;
    top: -30px;
    left: -30px;
    min-width: 120%;
    min-height: 120%;
    z-index: 0;
    /* background-color: #fff4; */
    /* filter: blur(10px); */
}

.backgroundOverlay {
    position: fixed;
    top: -30px;
    left: -30px;
    min-width: 120%;
    min-height: 120%;
    z-index: 0;
    background-color: #5551;
}

.content {
    z-index: 10;
}

.logoView {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 100px;
}

.vibeImage {
    width: 300px;
}

.gameImage {
    width: 250px;
    position: absolute;
    top: 70px;
}

.vibeText {
    /* font-family: 'Cherry Bomb One', sans-serif; */
    font-family: 'Rammetto One';
    font-size: 100pt; /* 200 */
    color: #fff;
    line-height: 80pt; /* 180 */
}

.gameText {
    /* font-family: 'Cherry Bomb One', sans-serif; */
    font-family: 'Rammetto One';

    font-size: 50pt; /* 150 */
    color: #fff;
    line-height: 30pt; /* 80 */
    margin-bottom: 100px;
}

.logo {
    width: 365px;
    height: auto;
}

.title {
    font-family: 'Raleway', sans-serif;
    font-size: 40pt;
    margin-bottom: 40px;
    font-weight: 900;
    font-style: italic;
    text-align: center;
}

.appstore {
    width: 200px;
    border: 2px solid #fff;
    border-radius: 13px;
}

.footer {
    position: relative;
    flex-direction: row;
    margin-top: 100px;
}

.link {
    font-family: 'Raleway', sans-serif;
    font-size: 12pt;
    margin-top: 50px;
    font-weight: 300;
    color: #fff7;
    margin: 0px 20px;
    text-decoration: none;
}

.copyright {
    font-family: 'Raleway', sans-serif;
    font-size: 10pt;
    margin-top: 50px;
    font-weight: 300;
}