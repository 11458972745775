.rootView {
    background-color: #fff;
    height: 100%;
    width: 100%;

    line-height: 1;
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 14px;
}

.header {
    font-family: 'Raleway', sans-serif;
    font-size: 35pt;
    margin-bottom: 40px;
    font-weight: 900;
    text-align: center;
    color: #000;
    border-bottom: 1px solid #ccc;
}

.supportView {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.usersView {
    width: 200px;
    overflow: auto;
    border-right: 2px solid #ccc;
}

.userItem {
    border-bottom: 2px solid #ddd;
    padding: 10px;
    cursor: pointer;
}

.userItemSelected {
    border-bottom: 2px solid #ddd;
    padding: 10px;
    cursor: pointer;
    background-color: #ccc;
}

.messagesView {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.messagesList {
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: auto;
    height: 80%;
}

.messagesActionsView {
    background: #f0f0f0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.agentInputText {
    width: 80%;
    padding: 5px;
}

.agentActionsView button {
    margin: 0px 10px;
}

.messageItemAgent {
    align-self: flex-end;
    padding: 10px;
    max-width: 400px;
    background: #f0f0f0;
    margin: 5px 0px;
    border-radius: 10px;
}

.messageItemUser {
    padding: 10px;
    max-width: 400px;
    border: 1px solid #ccc;
    background: #fff;
    margin: 5px 0px;
    border-radius: 10px;
}

.userName {
    font-weight: 700;
    margin-bottom: 10px;
}

.titleText {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-size: 20px;
    text-align: center;
}

.peopleView {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.personView {
    border-radius: 50px;
    width: 70px;
    height: 70px;
    margin-right: -7px;
    background-color: #ddd;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.messageItem {
    padding: 10px 20px;
    background-color: #f7f7f7;
    border-radius: 7px;
    margin: 10px 0px;
    border: 1px solid #ccc;
}

.replyToMessage {
    padding: 5px 10px;
    background-color: #f0f0f0;
    border-left: 5px solid #ccc;
    margin: 10px 0px;
    border-radius: 5px;
}

.messageTextView {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.messageText {
    font-family: 'Raleway';
    font-size: 18px;
    font-weight: 600;
}

.revealView {
    position: relative;
}

.revealButton {
    background-color: #000;
    border-radius: 7px;
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 800;
    width: 100px;
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
}

.writeMessageButton {
    background-color: #000;
    border-radius: 7px;
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 800;
    padding: 10px;
    margin: 20px 0px;
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
}

.numReveal {
    background-color: #f00;
    position: absolute;
    top: -10px;
    right: -10px;
    line-height: 16px;
    color: #fff;
    padding: 5px 9px;
    border-radius: 50px;
    font-weight: 700;
}