.rootView {
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 70px;

    font-family: 'Raleway', sans-serif;
    font-size: 9pt;
    font-weight: 500;
    line-height: 2.5;
}

.header {
    font-family: 'Montserrat', sans-serif;
    font-size: 35pt;
    margin-bottom: 40px;
    font-weight: 700;
    text-align: center;
}

.logo {
    width: 365px;
    height: auto;
}

.title {
    font-family: 'Raleway', sans-serif;
    font-size: 40pt;
    margin-bottom: 40px;
    font-weight: 900;
    font-style: italic;
    text-align: center;
}

.itemHeader {
    font-family: 'Raleway', sans-serif;
    margin-bottom: 20px;
    font-weight: 700;
}


.copyright {
    font-family: 'Raleway', sans-serif;
    font-size: 10pt;
    margin-top: 50px;
    font-weight: 300;
}