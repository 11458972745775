a {
    text-decoration: none;
}

.rootView {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 0.5px solid #fff7; */
    border-radius: 20px;
    /* padding: 50px 100px; */
    /* background: #fff2; */
    margin: 20px;
    z-index: 10;
}

/* .background {
    position: fixed;
    top: -30px;
    left: -30px;
    min-width: 120%;
    min-height: 120%;
    z-index: 0;
    filter: blur(10px);
} */

.background {
    background-image: linear-gradient(to bottom right, #ff8a48, #ff00c9);
    /* opacity: 0.5; */
    position: fixed;
    top: -30px;
    left: -30px;
    min-width: 120%;
    min-height: 120%;
    z-index: 0;
    /* background-color: #fff4; */
    /* filter: blur(10px); */
}

.backgroundOverlay {
    position: fixed;
    top: -30px;
    left: -30px;
    min-width: 120%;
    min-height: 120%;
    z-index: 0;
    background-color: #5551;
}


.logoView {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 50px;
}

.vibeImage {
    width: 100px;
}

.gameImage {
    width: 80px;
    position: absolute;
    top: 30px;
}

.vibeText {
    font-family: 'Cherry Bomb One', sans-serif;
    font-size: 40pt; /* 200 */
    color: #fff;
    line-height: 30pt; /* 180 */
}

.gameText {
    font-family: 'Cherry Bomb One', sans-serif;
    font-size: 20pt; /* 150 */
    color: #fff;
    line-height: 15pt; /* 80 */
    margin-bottom: 30px;
}

.logo {
    width: 365px;
    height: auto;
}

.title {
    font-family: 'Raleway', sans-serif;
    font-size: 40pt;
    margin-bottom: 40px;
    font-weight: 900;
    font-style: italic;
    text-align: center;
}

.appstore {
    width: 200px;
    border: 2px solid #fff;
    border-radius: 13px;
    margin-top: 40px;
}

.userView {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;

    border: 0.5px solid #fff7;
    border-radius: 20px;
    padding: 20px; /* 20 100 */
    background: #fff2;
}

.userPic {
    width: 50px; /* 100 */
    height: 50px; /* 100 */
    border: 2px solid #fff;
    border-radius: 100px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.userNoPicText {
    font-family: 'Montserrat', sans-serif;
    font-size: 10pt;
    font-weight: 400;
    color: #000;
}

.userInfoView {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.userName {
    font-family: 'Montserrat', sans-serif;
    font-size: 14pt; /* 20 */
    font-weight: 800;
}

.numNewQuizText {
    font-family: 'Montserrat', sans-serif;
    font-size: 10pt; /* 13 */
    font-weight: 600;
    text-align: center;
}


/* quizView: {
    // marginTop: 50,
    // marginBottom: 70,
    paddingHorizontal: 40,
    flex: 1,

    // alignItems: 'center',
    // justifyContent: 'center',
}, */

.questionNumText {
    font-size: 18px;
    color: #fff;
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    margin-top: 40px;
}

.questionView {
    background-color: #000;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px 40px;
    margin-top: 20px;
}

.questionText {
    font-size: 12pt; /* 16pt */
    color: #fff;
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
}

.answersView {
    background-color: #fff;
    padding: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.answerItemView {
    background-color: #f4f4f4;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px;
    padding: 10px;
    border-radius: 20px;

    color: #000;
    font-family: 'Raleway';
    font-weight: 600;
    font-size: 12pt; /* 14pt */
}


/* .questionView {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    margin-top: 20px;

    /* border: 0.5px solid #fff7;
    border-radius: 20px;
    padding: 20px 100px;
    background: #fff2;
}

.questionText {
    font-family: 'Montserrat', sans-serif;
    font-size: 18pt;
    font-weight: 800;
    margin-bottom: 20px;
    text-align: center;
}

.answersView {
    display: flex;
    flex-direction: column;
}

.answersRow {
    display: flex;
    flex-direction: row;
}

.answerItemView {
    font-family: 'Montserrat', sans-serif;
    font-size: 20pt;
    font-weight: 800;
    margin: 10px 0px;
    color: #000;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 40px;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 0px 0px 10px #fff;
}

.answerItemText {

} */

.downloadView {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    background: #fff;
    margin: 40px 0px 10px;
    box-shadow: #fff 0px 0px 30px;
}

.downloadText {
    font-family: 'Montserrat', sans-serif;
    font-size: 12pt;
    font-weight: 600;
    text-align: center;
    /* background: linear-gradient(to right, #00f289, #c7db00); */
    background: linear-gradient(to right, #ff8a48, #ff00c9);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer {
    position: relative;
    flex-direction: row;
    margin-top: 100px;
}

.link {
    font-family: 'Raleway', sans-serif;
    font-size: 12pt;
    margin-top: 50px;
    font-weight: 300;
    color: #fff7;
    margin: 0px 20px;
    text-decoration: none;
}

.copyright {
    font-family: 'Raleway', sans-serif;
    font-size: 10pt;
    margin-top: 50px;
    font-weight: 300;
}